// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import '../js/bootstrap_js_files.js'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'jquery-ui'

require('coffee')
import { SubTask } from '../coffee/sub_task.coffee';

Rails.start()
Turbolinks.start()
ActiveStorage.start()

$(function() {
  var $container = $('.app-view');
  var page = '';
  if($container[0]){
    page = $container.attr('id').match(/^sub-tasks-(.*)$/);
    if(page){
      SubTask.init(page[1]);
    }
  }
});
